import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { localeEs } from '@mobiscroll/angular';

@Component({
  selector: 'inputdate',
  templateUrl: './inputdate.component.html',
  styleUrls: ['./inputdate.component.scss'],
})
export class InputdateComponent implements OnInit {
  @Input() label: string = '';

  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  public myBirthday = '';

  public localeEs = localeEs;

  public formControl: FormControl = new FormControl('');

  private subscriptionFormControl!: Subscription;

  public day: string = '';

  public month: string = '';

  public year: string = '';

  public isFocus: boolean = false;

  public hasError: boolean = false;

  public error: string = '';

  public typeError: '' | 'day' | 'month' | 'year' | 'date' = '';

  private isValidate = false;

  @Input() validators: any;
  @Input() set value(date: string) {
    if (date === this.formControl.value) {
      return;
    }
    let value = date.replace('-', '');
    value = value.replace('-', '');
    this.formControl.setValue(date);
    this.setValue(value);
  }

  @Output() changeDate: EventEmitter<string> = new EventEmitter<string>();

  @Input() errorActive!: boolean;

  @Output() disableButton: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.subscribeFormControl();
  }

  private subscribeFormControl(): void {
    this.subscriptionFormControl = this.formControl.valueChanges.subscribe(
      (value) => {
        if (this.formControl.valid) {
          this.setValue(value);
        }
        if (
          !this.day ||
          !this.month ||
          !this.year ||
          this.typeError == 'day' ||
          this.typeError == 'month' ||
          this.typeError == 'year' ||
          this.typeError == 'date' ||
          this.year.length < 4
        ) {
          this.errorActive = true;
          this.disableButton.emit({ error: true });
        } else {
          this.errorActive = false;
          this.disableButton.emit({ error: false });
        }
      }
    );
  }

  private setValue(value: any): any {
    if (this.validateCharacters(value)) return;
    this.createMask(value);
    this.validateDate();
  }

  private validateDate(): void {
    this.hasError = false;
    this.error = '';
    this.typeError = '';
    this.isValidate = false;
    if (this.validateDay()) return;
    if (this.validateMonth()) return;
    if (this.validateYear()) return;
    if (this.year.length > 3) {
      this.isValidate = true;
      const date = `${this.day}-${this.month}-${this.year}`;
      this.changeValue.emit(date);
    }
  }

  private validateDay(): any {
    if (this.day.length < 2) return;
    const day = Number(this.day);
    if (day > 31 || day < 1) {
      this.hasError = true;
      this.error = 'El dia ingresado es invalido';
      this.typeError = 'day';
      return true;
    }
    return;
  }

  private validateMonth(): any {
    if (this.month.length < 2) return;
    const month = Number(this.month);
    if (month > 12 || month < 1) {
      this.hasError = true;
      this.error = 'El mes ingresado es invalido';
      this.typeError = 'month';
      return true;
    }
    return;
  }

  private validateYear(): any {
    if (this.year.length < 4) return true;
    const now = new Date();
    const now_time = now.getTime();
    const date = new Date(
      `${this.getYear()}/${this.getMonth()}/${this.getDay()}T00:00:00`
    );
    const date_time = date.getTime();
    const date_month = date.getMonth() + 1;
    /* if (date_month != Number(this.month)) {
      this.hasError = true;
      this.error = 'El dia ingresado no es valido';
      this.typeError = 'day';
      return true;
    } */
    if (
      Number(this.getYear()) > now.getFullYear() ||
      (Number(this.getYear()) === now.getFullYear() &&
        Number(this.getMonth()) > now.getMonth() + 1)
    ) {
      this.hasError = true;
      this.error = 'No puedes ingresar una fecha mayor a la actual';
      this.typeError = 'date';
      return true;
    }
    return;
  }

  private validateCharacters(value: any): any {
    if (!/^[0-9]+$/.test(value) && value) {
      let data = value.replace(value[value.length - 1], '');
      this.formControl.setValue(data);
      return true;
    }
    return;
  }

  private createMask(value: any): void {
    this.day = '';
    this.month = '';
    this.year = '';
    for (let p = 0; p < 8; p++) {
      const data = value[p] ? value[p] : '';
      if (p === 0) {
        this.day = data;
      }
      if (p === 1) {
        this.day += data;
      }
      if (p === 2) {
        this.month = data;
      }
      if (p === 3) {
        this.month += data;
      }
      if (p === 4) {
        this.year = data;
      }
      if (p === 5) {
        this.year += data;
      }
      if (p === 6) {
        this.year += data;
      }
      if (p === 7) {
        this.year += data;
      }
    }
  }

  public getDay(): string {
    if (this.day.length > 1) return this.day;
    return this.day + 'D';
  }

  public getMonth(): string {
    if (this.month.length > 1) return this.month;
    return this.month + 'M';
  }

  public getYear(): string {
    if (this.year.length > 3) return this.year;
    if (this.year.length > 2) return this.year + 'Y';
    if (this.year.length > 1) return this.year + 'YY';
    return this.year + 'YYY';
  }

  public focus(): void {
    this.isFocus = true;
  }

  public focusout(): void {
    this.isFocus = false;
    if (
      !this.isValidate &&
      !this.typeError &&
      !this.hasError &&
      !this.errorActive
    ) {
      this.hasError = true;
      this.error = !this.day
        ? this.validators.error.requiered.text
        : this.validators.error.requiered.error;
      this.typeError = 'date';
    }
  }

  public validatePositionOfCursor(e: any): void {
    let value = this.formControl.value;
    if (e.target.selectionEnd === value.length) {
      return;
    }
    value = value.slice(0, e.target.selectionEnd);
    this.formControl.setValue(value);
  }
}
