import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
@Component({
  selector: 'inputbuttons',
  templateUrl: './inputbuttons.component.html',
  styleUrls: ['./inputbuttons.component.scss'],
})
export class InputbuttonsComponent implements OnInit {
  @Input() label: string = '';

  @Input() options: Array<any> = [];

  @Output() change: EventEmitter<any> = new EventEmitter();

  @Output() selectoption: EventEmitter<any> = new EventEmitter();

  public showModalConfirm: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public next(): void {
    this.change.emit();
  }

  public selected(value: any): void {
    if (value.confirm) {
      value = {
        label: 'No especificado',
        next: 'peso',
        value: ' ',
      };
      this.selectoption.emit(value);
      return;
    }
    if (value.label === 'No especificado') {
      this.showModalConfirm = true;
    } else {
      this.showModalConfirm = false;
      this.selectoption.emit(value);
    }
  }

  public closeModal() {
    this.showModalConfirm = false;
  }
}
