import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/general.services';

@Component({
  selector: 'inputautocomplete',
  templateUrl: './inputautocomplete.component.html',
  styleUrls: ['./inputautocomplete.component.scss'],
})
export class InputautocompleteComponent implements OnInit {
  @Input() label: string = '';

  @Input() optionslist: any[] = [];

  @Input() placeholder: string = '';

  public valueControl: FormControl = new FormControl('');

  public list: any[] = [];

  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  public isVisibleMenu: boolean = false;

  private subscriptionFormControl!: Subscription;

  public formControl: FormControl = new FormControl('', [Validators.required]);

  @Input() set value(value: string) {
    if (value === this.formControl.value) {
      return;
    }
    /* if (this.subscriptionFormControl) {
      this.subscriptionFormControl.unsubscribe();
    } */
    this.formControl.setValue(value);
    this.subscribeFormControl();
  }

  @Output() selectautocomplete: EventEmitter<any> = new EventEmitter();

  @Output() selectoption: EventEmitter<any> = new EventEmitter();

  @Input() validators: any;

  @Input() errorActive!: boolean;

  @Output() disableButton: EventEmitter<any> = new EventEmitter();

  @Input() newlist: any[] = [];

  @Input() newlistdefect: any[] = []; 

  @Input() route: any;

  @Input() routetype: any;

  @Input() searchrequest: any;

  @Input() filter: any[] = [];

  @Input() searchrules: any;

  @Input() isCustome!: boolean;

  @Input() type:string = "";

  public searchEvent: any = null;

  constructor(
    private _elementRef: ElementRef,
    public _general: GeneralService
  ) {}

  @HostListener('document:mousedown', ['$event'])
  click(event: any): void {
    if (this._elementRef.nativeElement.contains(event.target)) {
    } else {
      this.valueControl.reset();
      this.isVisibleMenu = false;
    }
    if (
      !this._elementRef.nativeElement.contains(event.target) &&
      this.newlist.length === 0
    ) {
      this.disableButton.emit({ error: true });
    }
  }

  ngOnInit(): void {
 /*  const list = [
    { id: '00001', name: 'Dolor muscular' } , { id:'00002' , name:'Dolor agudo'}
  ]
  this.newlist.push(list); */
  
  
    this.subscribeFormControl();
    this.valueControl.valueChanges.subscribe(async (value) => {
      if (!value) {
        if(this.label === 'Seleccione el recuadro, busque y seleccione las enfermedades, también puede escribirla otras y seleccionarlas.'){
          this.list = []
          this.defaultOptions();
          return;
        }
        this.isCustome = false;
        this.isVisibleMenu = !this.isVisibleMenu;
        return;
      }
      
      this.isVisibleMenu = true;
      this.valueChanges(value, this.searchrequest);
      this.isCustome = true;
    });
  }

  public selectAutocomplete(option: any): void {
    if (!this.newlist.includes(option)) {
      this.newlist.push(option);
    }
    this.list = []
    this.valueControl.reset();
    this.errorActive = false;
    this.disableButton.emit({ error: false });
    this.selectoption.emit(this.newlist);

  }

  public defaultOptions():void{
   
  
    if(this.label === 'Seleccione el recuadro, busque y seleccione las enfermedades, también puede escribirla otras y seleccionarlas.'){
 
      const values = [
        {
          name:"Hipertensión esencial (primaria)",
          id: 'I10X', 
          code: 'I10'
        },
        {
          name:"Diabetes mellitus tipo 2",
          id: 'E11', 
          code: 'E11'
        },
        {
          name:"Asma",
          id: 'J45', 
          code: 'J45'
        },
          {
            name: 'Hipotiroidismo, no especificado', 
            id: 'E039', 
            code: 'E03.9'
          },
          {
            name: 'Hipertensión secundaria', 
            id: 'I15', 
            code: 'I15'
          },
          {
            name: 'Insomnio', 
            id: 'G470', 
            code: 'G47.0'
          },
          {
            name: 'Otros tipos de hipotiroidismo', 
            id: 'E03', 
            code: 'E03'
          },
          {
            name: 'Trastorno mixto de ansiedad y depresión', 
            id: 'F412', 
            code: 'F41.2'
          },
          {
            name: 'Enfermedad cardiaca hipertensiva', 
            id: 'I11', 
            code: 'I11'
          },
          {
            name: 'Epilepsia y crisis epilépticas recurrentes', 
            id: 'G40', 
            code: 'G40'
          },
          {
            name: 'Otros trastornos de ansiedad', 
            id: 'F41', 
            code: 'F41'
          },
          {
            name: 'Diabetes mellitus tipo 1', 
            id: 'E10', 
            code: 'E10'
          },
          {
            name: 'Otra hipertensión pulmonar secundaria', 
            id: 'I272', 
            code: 'I27.2'
          },
          {
            name: 'Trastorno de ansiedad debido a afección fisiológica conocida', 
            id: 'F064', 
            code: 'F06.4'
          },
          {
            name: 'Trastorno de ansiedad generalizada', 
            id: 'F411', 
            code: 'F41.1'
          },
          {
            name: 'Diabetes mellitus tipo 2 sin complicaciones', 
            id: 'E119', 
            code: 'E11.9'
          },
          {
            name: 'Sobrepeso y obesidad', 
            id: 'E66', 
            code: 'E66'
          },
          {
            name: 'Migraña', 
            id: 'G43', 
            code: 'G43'
          },
          {
            name: 'Otras rinitis alérgicas', 
            id: 'J30.3', 
            code: 'J303'
          },
      ];
      this.isCustome = false;
      this.list = values;
      this.isVisibleMenu = !this.isVisibleMenu;
      return;
      }
      this.isCustome = false;
      this.isVisibleMenu = !this.isVisibleMenu;

  }

  public deleteItem(index: any) {
    this.newlist.splice(index, 1);
    if (this.newlist.length === 0) {
      this.errorActive === true;
      this.disableButton.emit({ error: true });
    }
  }

  private subscribeFormControl(): void {
    this.subscriptionFormControl = this.valueControl.valueChanges.subscribe(
      (value) => {
        this.changeValue.emit(value);
      }
    );
  }

  private valueChanges(value: any, request: boolean): void {
    try {
      clearTimeout(this.searchEvent);
      console.log("limpie el timeout");
    } catch (error) {
      //
    }
    this.searchEvent = setTimeout(() => {
      console.log("Entre dentro del timeout");
      if (value !== null && value.includes(value)) {
        if (value.includes(',')) {
          value = value.replace(',', '');
        }
        this.search(value, request);
      }
    }, 500);
  }

  public async openSelect(): Promise<void> {
    this.isVisibleMenu = true;
  }

  public async search(value?: any, request?: boolean): Promise<void> {
    let res: any = await this.get();

    this.optionslist = [];
    if (Array.isArray(res)) {
      this.optionslist = res;
    }
    let valueFinal = value?.toLowerCase();
    let result: any;

    if (request === false) {
      result = this.optionslist;
    }

    if (request === true) {
      if (value.length > this.searchrules.minLength) {
        if (Array.isArray(this.optionslist)) {
          //filter by name
          result = this.optionslist.filter((x: any) =>
            x[this.filter[0].attr]
              .toLowerCase()
              .includes(valueFinal.toLowerCase())
          );
        //filter by summary
          if (this.filter.length === 2 ){

            const result_2 = this.optionslist.filter((x:any) => {
              if(x[this.filter[1].attr]){
               return  x[this.filter[1].attr]
                .toLowerCase()
                .includes(valueFinal.toLowerCase())
              }
            
            }
            
            );
            result = result.concat(result_2)
          }
        }
      }
    }

    if (result) {
      this.list = result;
    }
  }

  public async get(): Promise<any> {
    let data: any;
    if (this.valueControl.value.length < 3) {
      return [];
    }
    if (this.routetype == 'external') {
      let route = this.route;
      route = route.replace('%value%', this.valueControl.value);
      data = await this._general.getInfo(route);
    }
    if (this.routetype == 'local') {
      data = await this._general.getInfoLocal(this.route);
    }
    return data;
  }

  public async set(value: any): Promise<void> {
    this.newlist.push({ id: '', name: value });
    this.valueControl.reset();
    this.isVisibleMenu = false;
  }
}
