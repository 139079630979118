import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { FormService } from '../services/forms.services';

@Injectable({
  providedIn: 'root',
})
export class ViewGuard implements CanActivate {
  constructor(private _form: FormService, private _router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const { id } = route.params;
    const { key } = route.queryParams;
    try {
      if (!id) {
        throw 'notfound';
      }
      if (!key) {
        throw 'unauthorized';
      }
      let res = await this._form.getInfo(id, key);
      if (!res) {
        throw 'notfound';
      }
      return true;
    } catch (error) { 
      if (error === 'pending') {
        this._router.navigateByUrl(`view/${id}/pending`);
        return false;
      }
      if (error === 'notfound') {
        this._router.navigateByUrl(`view/${id}/notfound`);
        return false;
      }
      this._router.navigateByUrl(`view/${id}/unauthorized`);
      return false;
    }
  }
}
