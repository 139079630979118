import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExitGuard } from './guards/exit.guard';
import { FormGuard } from './guards/form.guard';
import { ViewGuard } from './guards/view.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'form/:id',
   // canDeactivate: [ExitGuard],
    loadChildren: () =>
      import('./pages/form/form.module').then((m) => m.FormModule),
    canActivate: [FormGuard]
  },
  {
    path: 'view/:id',
    loadChildren: () =>
      import('./pages/view/view.module').then((m) => m.ViewModule),
    canActivate: [ViewGuard],
  },
  {
    path: 'form/:id/completed',
    loadChildren: () =>
      import('./pages/form-completed/form-completed.module').then(
        (m) => m.FormCompletedModule
      ),
  },
  {
    path: 'home/:form_url',
    loadChildren: () =>
      import('./pages/form-create/form-create.module').then(
        (m) => m.FormCreatedModule
      ),
  },
  {
    path: 'form/:id/notfound',
    loadChildren: () =>
      import('./pages/form-notfound/form-notfound.module').then(
        (m) => m.FormNotfoundModule
      ),
  },
  {
    path: 'view/:id/notfound',
    loadChildren: () =>
      import('./pages/view-notfound/view-notfound.module').then(
        (m) => m.ViewNotfoundModule
      ),
  },
  {
    path: 'view/:id/unauthorized',
    loadChildren: () =>
      import('./pages/view-unauthorized/view-unauthorized.module').then(
        (m) => m.ViewUnauthorizedModule
      ),
  },
  {
    path: 'view/:id/pending',
    loadChildren: () =>
      import('./pages/view-pending/view-pending.module').then(
        (m) => m.ViewPendingModule
      ),
  },
  { path: 'form-suspend/:form_url', loadChildren: () => import('./pages/form-suspend/form-suspend.module').then(m => m.FormSuspendModule) },
  {
    path: ':id',
    loadChildren: () => import('./pages/qr/qr.module').then((m) => m.QrModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ExitGuard]
})
export class AppRoutingModule {}
