<main>
  <h2>{{ label }}</h2>
  <div class="input-container">
    <input
      type="text"
      [formControl]="formControl"
      [placeholder]="placeholder"
    />
    <p class="inputdate-text-error" *ngIf="requiered || errorActive">
      {{ validators.error?.requiered?.text }}
    </p> 
    <div *ngIf="formControl.errors && this.validators?.error?.email">
      <p
        class="inputdate-text-error"
        *ngIf="formControl.errors['email'] && !formControl.errors['required']"
      >
        {{ validators?.error?.email?.text }}
      </p>
    </div> 
  </div>
</main>
