<main>
  <h2>{{ label }}</h2>
  <div class="input-container">
    <input
      [formControl]="valueControl"
      [ngClass]="isVisibleMenu ? 'input-active-border' : ''"
      type="text"
      (click)="defaultOptions()"
      (keypress)="openSelect()"
      id="inputSearch"
      [placeholder]="placeholder"
      autocomplete="off"
    />
    <div class="autocomplete" *ngIf="isVisibleMenu">
      <ul>
        <li *ngIf="isCustome">
          <div class="autocomplete-item" (click)="set(valueControl.value)">
            <p>{{ valueControl.value }}</p>
          </div>
        </li>
          <li (click)="selectAutocomplete(option)" *ngFor="let option of list">
            <p>{{ option.name }}</p>
          </li>
      </ul>
    </div>
    <div class="list">
      <div class="nh-chip" *ngFor="let item of newlist; index as i">
        <p>{{ item.name }}</p>
        <span (click)="deleteItem(i)"> x</span>
      </div>
    </div>
    <p *ngIf="errorActive" class="inputdate-text-error">
      Este campo es Requerido.
    </p>
  </div>
</main>
