import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';




@Injectable({
  providedIn: 'root'
})
export class ExitGuard implements CanDeactivate<unknown> {
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return Swal.fire({
        title: '¿Seguro que quieres salir?',
        text: "Al salir se perderá tu información ingresada",
        showCancelButton: true,
        confirmButtonColor: '#1F1F37',
        cancelButtonColor: '#1B56A5',
        confirmButtonText: 'Quiero salir',
        cancelButtonText: 'Quedarme aquí'
      }).then((result:any) => {
        if (result.isConfirmed) {
          return true
        }
        console.log('actual estado:', component)
        return false;
      
      })
    }
}
