import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { QuestionComponent } from 'src/app/pages/form/question/question.component';

@Component({
  selector: 'inputext',
  templateUrl: './inputext.component.html',
  styleUrls: ['./inputext.component.scss'],
})
export class InputextComponent implements OnInit {
  @Input() label: string = '';

  @Input() placeholder: string = '';

  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  private subscriptionFormControl!: Subscription;

  public formControl: FormControl = new FormControl('', [Validators.required]);

  @Input() set value(value: string) {
    if (value === this.formControl.value) {
      return;
    }
    if (this.subscriptionFormControl) {
      this.subscriptionFormControl.unsubscribe();
    }
    this.formControl.setValue(value);
    this.subscribeFormControl();
  }

  @Input() validators: any;

  @Input() errorActive!: boolean;

  @Output() disableButton: EventEmitter<any> = new EventEmitter();

  @Output() registrarValue:any = new EventEmitter();

  public requiered: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.subscribeFormControl();
  }

  private subscribeFormControl(): void {
    this.subscriptionFormControl = this.formControl.valueChanges.subscribe(
      (value) => {
        const errors: any = this.formControl?.errors;
        if (!value ) {
          this.requiered = true;
          this.changeValue.emit();
          this.disableButton.emit({ error: true });
        } else {
          this.requiered = false;
          this.changeValue.emit(value);
          this.disableButton.emit({ error: false });
        }
        if(this.validators){
          if (this.validators?.error?.email) {
            this.formControl.setValidators([Validators.email]);
            if(value.length === 1){
              this.disableButton.emit({error:true})
              return;
            }
          } else {
            this.changeValue.emit(value);
            this.disableButton.emit({ error: false });
          }
        }

        
        if (errors?.email) {
          this.requiered = false;
          this.errorActive = false;
          this.disableButton.emit({ error: true });
          return;
        } else {
          this.disableButton.emit({ error: false });
        }
      }
    );
  }
}
