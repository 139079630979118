<main>
  <h2>{{ label }}</h2>
  <div class="input-container">
    <input
      (click)="openSelect()"
      [ngClass]="isVisibleMenu ? 'input-active-border' : ''"
      type="text"
      [placeholder]="placeholder"
      [formControl]="formControl"
      autocomplete="off"
      readonly
    />
    <div class="autocomplete autocomplete-large" *ngIf="isVisibleMenu">
      <ul>
        <li (click)="selectWeek(option)" *ngFor="let option of dataSearch">
          <p>{{ option.name }}</p>
        </li>
      </ul>
    </div>
    <p *ngIf="errorActive" class="inputdate-text-error">
      Este campo es Requerido.
    </p>
  </div>
</main>
