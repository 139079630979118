import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public termOrPrivacy!: string;

  public url = environment.api + '/forms';

  constructor(private _http: HttpClient) {
    this.element = window.document.getElementById('spinner');
  }

  private element: any;

  public show(): void {
    this.element.classList.add('spinner-show');
  }

  public hide(): void {
    this.element.classList.remove('spinner-show');
  }

  async getInfoLocal(route: any): Promise<any> {
    try {
      if (!route) {
        return [];
      }
      let data = this._http.get(`./assets/catalogs/${route}.json`);
      let res = await lastValueFrom(data);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  async getInfo(route: string): Promise<any> {
    try {
      let req: Observable<any> = await this._http.get(route);
      let res = await lastValueFrom(req);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }
}
