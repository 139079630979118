import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputdateComponent } from './inputdate/inputdate.component';
import { InputextComponent } from './inputext/inputext.component';
import { InputbuttonsComponent } from './inputbuttons/inputbuttons.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { InputnumberComponent } from './inputnumber/inputnumber.component';
import { InputselectComponent } from './inputselect/inputselect.component';
import { InputautocompleteComponent } from './inputautocomplete/inputautocomplete.component';
import { MbscModule } from '@mobiscroll/angular';

@NgModule({
  declarations: [
    InputdateComponent,
    InputextComponent,
    InputbuttonsComponent,
    InputnumberComponent,
    InputselectComponent,
    InputautocompleteComponent,
  ],
  imports: [ReactiveFormsModule, CommonModule, MbscModule],
  exports: [
    InputdateComponent,
    InputextComponent,
    InputbuttonsComponent,
    InputnumberComponent,
    InputselectComponent,
    InputautocompleteComponent,
    MbscModule,
  ],
})
export class InputModule {}
