import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'inputnumber',
  templateUrl: './inputnumber.component.html',
  styleUrls: ['./inputnumber.component.scss'],
})
export class InputnumberComponent implements OnInit {
  @Input() label: string = '';

  @Input() validators: any;

  private subscriptionFormControl!: Subscription;

  public errorPattern = false;

  public showMessageError: boolean = false;

  public formControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[0-9+]*$'),
  ]);

  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  @Input() placeholder: string = '';

  @Input() set value(value: string) {
    if (value === this.formControl.value) {
      return;
    }
    if (this.subscriptionFormControl) {
      this.subscriptionFormControl.unsubscribe();
    }
    this.formControl.setValue(value);
    this.subscribeFormControl();
  }

  @Input() errorActive!: boolean;

  @Output() disableButton: EventEmitter<any> = new EventEmitter();

  public minLengt: boolean = false;

  public maxLengt: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.subscribeFormControl();
    if(this.label === 'Monedero de ahorro'){
      this.showMessageError = false;
    }else{
      this.showMessageError = true;
    }
  }

  private subscribeFormControl(): void {
    this.subscriptionFormControl = this.formControl.valueChanges.subscribe(
      (value) => {
        if (!value) {

          this.formControl.markAllAsTouched();
          this.disableButton.emit({ error: true });
          this.minLengt = false;
          this.errorPattern = false;
          this.errorActive = true;
          this.changeValue.emit(value);
          return;
        }
        if (value) {
          this.errorActive = false;
          let number: number = 0;
          number = this.formControl.value.length;
          if (this.validators.error.minLength) {
            if (
              number < this.validators.error.minLength.value &&
              this.errorPattern == false
            ) {
              this.minLengt = true;
              this.errorPattern = false;
              this.disableButton.emit({ error: true });
            } else if (this.formControl.getError('pattern')) {
              this.minLengt = false;
              this.errorPattern = true;
              this.disableButton.emit({ error: true });
            } else {
              this.minLengt = false;
              this.changeValue.emit(value);
              this.disableButton.emit({ error: false });
            }
          } else {
            this.changeValue.emit(value);
            this.disableButton.emit({ error: false });
          }
        }

        const errors: any = this.formControl.errors;

        if (errors?.pattern) {
          this.errorPattern = true;
          this.disableButton.emit({ error: true });
        } else {
          this.errorPattern = false;
        }
      }
    );
  }
}
