import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/general.services';

@Component({
  selector: 'inputselect',
  templateUrl: './inputselect.component.html',
  styleUrls: ['./inputselect.component.scss'],
})
export class InputselectComponent implements OnInit {
  @Input() label: string = '';

  @Input() options: Array<any> = [];

  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() placeholder: string = '';

  public isVisibleMenu: boolean = false;

  private subscriptionFormControl!: Subscription;

  public formControl: FormControl = new FormControl('', [Validators.required]);

  public dataSearch: any = [];

  @Input() set value(value: string) {
    if (value === this.formControl.value) {
      return;
    }
    /* if (this.subscriptionFormControl) {
      this.subscriptionFormControl.unsubscribe();
    } */
    this.formControl.setValue(value);
    this.subscribeFormControl();
  }

  @Output() selectoption: EventEmitter<any> = new EventEmitter();

  @Input() validators: any;

  @Input() errorActive!: boolean;

  @Output() disableButton: EventEmitter<any> = new EventEmitter();

  public active: boolean = false;

  @Input() inputType: any;

  @Input() route: any;

  @Input() routetype: any;

  @Input() searchrequest: any;

  @Input() filter: any[] = [];

  @Input() searchrules: any;

  constructor(
    private _elementRef: ElementRef,
    public _general: GeneralService
  ) {}

  @HostListener('document:mousedown', ['$event'])
  click(event: any): void {
    if (this._elementRef.nativeElement.contains(event.target)) {
    } else {
      this.isVisibleMenu = false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.subscribeFormControl();
    let res: any = await this.get();
    if (this.searchrequest === false) {
      this.options = res;
      this.dataSearch = res;
    }
  }

  public openSelect(): void {
    this.isVisibleMenu = !this.isVisibleMenu;
  }

  public selectWeek(option: any): void {
    this.isVisibleMenu = false;
    this.selectoption.emit(option);
    this.formControl.setValue(option.name);
  }

  private subscribeFormControl(): void {
    this.subscriptionFormControl = this.formControl.valueChanges.subscribe(
      (value) => {
        if (!this.validators?.error) {
          this.change.emit(value);
          this.disableButton.emit({ error: false });
          return;
        }
        if (!value) {
          this.change.emit(value);
          this.disableButton.emit({ error: true });
        }

        if (this.formControl.valid) {
          this.change.emit(value);
          this.disableButton.emit({ error: false });
        }
      }
    );
  }

  public async get(): Promise<any> {
    let data: any;
    if (this.routetype == 'external') {
      data = await this._general.getInfo('');
    }

    if (this.routetype == 'local') {
      data = await this._general.getInfoLocal(this.route);
    }

    return data;
  }
}
