import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import {InputModule} from './comps/inputs/input.module';
 


@NgModule({
  declarations: [AppComponent],
  imports: [ 
    FormsModule,  BrowserModule, AppRoutingModule, HttpClientModule,InputModule ],
  providers: [],
  bootstrap: [AppComponent],
  exports:[
  ]
})
export class AppModule {}
