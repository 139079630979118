import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, withLatestFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  public url = environment.api + '/forms';

  public form_config: any = {};

  constructor(private _http: HttpClient) {}

  async createForm(data: any): Promise<any> {
    try {
      let req: Observable<any> = await this._http.post(`${this.url}`, data, {
        headers: {
          'x-api-key': 'api_key_forms',
        },
      });
      let res = await lastValueFrom(req);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  public saveFormUrl(form_url: any): void {
    this.form_config = {
      form_url
    }
  }

  public saveFormConfig(form_config:any):void{
    this.form_config = form_config;
  }

  public getFormConfig():any{
    return this.form_config;
  }

  public getformUrl(): any {
    return this.form_config.form_url;
  }

  async getConfig(id: string): Promise<any> {
    try {
      let req: Observable<any> = await this._http.get(
        `${this.url}/config?id=${id}`
      );
      let res = await lastValueFrom(req);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  async getUrl(id: string): Promise<any> {
    try {
      let req: Observable<any> = await this._http.get(`${this.url}/url/${id}`);
      let res = await lastValueFrom(req);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  async getInfo(id: string, api_key: string): Promise<any> {
    try {
      let req: Observable<any> = await this._http.get(
        `${this.url}/${id}/info?key=${api_key}`
      );
      let res = await lastValueFrom(req);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  async getForm(id: string): Promise<any> {
    try {
      let req: Observable<any> = await this._http.get(`${this.url}?id=${id}`);
      let res = await lastValueFrom(req);
      const start_date = new Date().getTime();
      res.start_date = start_date;
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  async getQuestions(id: string): Promise<any> {
    try {
      const data = this._http.get(`./assets/json/${id}.json`);
      let res = await lastValueFrom(data);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  async getUserSearch(external_id: string): Promise<any> {
    try {
      const data = this._http.get(
        `${environment.api}/farmacia_ahorro/user/search?external_id=${external_id}`
      );
      let res = lastValueFrom(data);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }

  async updateForm(id: string, data: any): Promise<any> {
    try {
      if (data.data.gender === ' ') {
        data.data.gender = '';
      }
      delete data.data.searchPacient;
      delete data.data.pacientYounger;
      delete data.data.pacientYoungerAlert;
      delete data.data.sintomasAlert;
      let req: Observable<any> = await this._http.post(
        environment.api + `/forms/${id}/update`,
        data
      );
      let res = await lastValueFrom(req);
      return res;
    } catch (error: any) {
      throw error.message;
    }
  }
}
