<div
  class="inputdate-container"
  [ngClass]="{ 'inputdate-container-error': hasError }"
>
  <label for="input">{{ label }}</label>
 <!--  <mbsc-datepicker
    display="bottom"
    [controls]="['date']"
    [locale]="localeEs"
    [touchUi]="true"
    themeVariant="light"
    [formControl]="formControl"
  ></mbsc-datepicker> -->
  <div class="inputdate">
    <input
      [formControl]="formControl"
      type="text"
      name="input"
      id="inputdate"
      maxlength="8"
      (focus)="focus()"
      (mouseup)="validatePositionOfCursor($event)"
      (keyup)="validatePositionOfCursor($event)"
      (focusout)="focusout()"
    />
    <p class="inputdate-mask">
      <span
        class="inputdate-mask-value"
        *ngIf="day"
        [ngClass]="{
          'inputdate-focus': day.length < 2 && isFocus,
          'inputdate-value-error': typeError === 'day' || typeError === 'date'
        }"
        >{{ getDay() }}</span
      >
      <span
        class="inputdate-mask-label"
        *ngIf="!day"
        [ngClass]="{
          'inputdate-focus': !day && isFocus,
          'inputdate-value-error': typeError === 'day' || typeError === 'date'
        }"
        >DD</span
      >
      -
      <span
        class="inputdate-mask-value"
        *ngIf="month"
        [ngClass]="{
          'inputdate-focus': day.length > 1 && month.length < 2 && isFocus,
          'inputdate-value-error': typeError === 'month' || typeError === 'date'
        }"
        >{{ getMonth() }}</span
      >
      <span
        class="inputdate-mask-label"
        *ngIf="!month"
        [ngClass]="{
          'inputdate-focus': day.length > 1 && !month && isFocus,
          'inputdate-value-error': typeError === 'month' || typeError === 'date'
        }"
        >MM</span
      >
      -
      <span
        class="inputdate-mask-value"
        *ngIf="year"
        [ngClass]="{
          'inputdate-focus': month.length > 1 && isFocus,
          'inputdate-value-error': typeError === 'year' || typeError === 'date'
        }"
        >{{ getYear() }}</span
      >
      <span
        class="inputdate-mask-label"
        *ngIf="!year"
        [ngClass]="{
          'inputdate-focus': month.length > 1 && !year && isFocus,
          'inputdate-value-error': typeError === 'year' || typeError === 'date'
        }"
        >YYYY</span
      >
    </p>
  </div>
  <div>
    <p *ngIf="hasError" class="inputdate-text-error">{{ error }}</p>
    <p *ngIf="errorActive && !hasError" class="inputdate-text-error">
      {{ validators.error.requiered.text }}
    </p>
  </div>
</div>
