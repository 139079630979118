import { ENVIRONMENT } from './environment.interface';

export const environment: ENVIRONMENT = {
  production: false,
  version: '1.0.0',
  env: 'dev',
  firebase: {
    apiKey: 'AIzaSyBv6IKG5ghD3bv4h8PxP_X23ES-1UCXv1I',
    authDomain: 'nuuphealth-dev.firebaseapp.com',
    projectId: 'nuuphealth-dev',
    storageBucket: 'nuuphealth-dev.appspot.com',
    messagingSenderId: '1051638623544',
    appId: '1:1051638623544:web:e1a881386145a8f3c4e5cd',
    measurementId: 'G-M48F0DMN6T',
  },
  api: 'https://api-dev.nuuphealth.com'
};
