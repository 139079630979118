import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FormService } from '../services/forms.services';

@Injectable({
  providedIn: 'root',
})
export class FormGuard implements CanActivate {
  constructor(private _form: FormService, private _router: Router) {}
  async canActivate(route: ActivatedRouteSnapshot) {
    const { id } = route.params;
    try {
      if (!id) {
        throw 'El parametro id es obligatorio';
      }
      let res = await this._form.getForm(id);
      if (!res) {
        throw 'notfound';
      }
      if (res.status !== 'created') {
        throw res.status;
      }
      return true;
    } catch (error: any) {
      if (error === 'pending') {
        this._router.navigateByUrl(`form/${id}/completed`);
        return false;
      }
      this._router.navigateByUrl(`form/${id}/notfound`);
      return false;
    }
  }
}
