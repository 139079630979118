<div>
  <h2>{{ label }}</h2>
  <input
    type="text"
    [formControl]="formControl"
    class="form-control"
    [placeholder]="placeholder"
  />
  <p
    *ngIf="errorActive && !errorPattern && !minLengt"
    class="inputdate-text-error"
  >
    {{ validators.error.requiered?.text || ''}}
  </p>
  <p class="inputdate-text-error" *ngIf="errorPattern && showMessageError">
    Solo se permiten numeros
  </p>
  <p class="inputdate-text-error" *ngIf="minLengt ">
    {{ validators.error.minLength?.text  || ''}}
  </p>
</div>
