<main>
  <h2>{{ label }}</h2>
  <div *ngFor="let item of options">
    <button (click)="next(); selected(item)">{{ item.label }}</button>
  </div>
</main>


<div class="dialog" *ngIf="showModalConfirm">
  <div class="dialog-back"></div>
  <div class="dialog-main">
    <section>
     
      <img
        (click)="closeModal()"
        class="nuup-pointer icon-close"
        src="../../../assets/icon-close.svg"
        alt="iconclose"
      />
    </section>
    <main>
      <div class="_3K7uv">
        <div id="Containereejqq" class="_1KV2M">
          <header>
            <article>
              <h2 class="nh-txt18-medium d-flex align-items-center">
                Favor de confirmar
              </h2>
              <p class="p-confirm"> El género de nacimiento es muy relevante para poder hacer un diagnóstico correcto. (Esta información será solicitada por el médico)
              </p>
              <div class="btn-container" >
                <button class="btn-modal" (click)="next(); selected({ label:'sdssd', confirm:true})">Continuar sin especificar</button>
                <button class="btn-modal" (click)="closeModal()">Regresar a especificar</button>
              </div>
            </article>
          </header>
        </div>
      </div>

     
    </main>
  </div>
</div>
