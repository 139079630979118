import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { FormService } from './services/forms.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();
  title = 'form';
  
  constructor(
    private _form:FormService,
    private _router:Router
    ){}

  ngOnInit(): void {
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(true, '');
       // this.showModal();
      });
  }

  async showModal() {
    let result = await Swal.fire({
      title: '¿Seguro que quieres salir?',
      text: 'se perderá tu información!',
      showCancelButton: true,
      confirmButtonColor: '#1F1F37',
      cancelButtonColor: '#1B56A5',
      confirmButtonText: 'Quiero salir.',
      cancelButtonText: 'Quedarme aquí.',
    });
    if (!result.isConfirmed) {
      console.log('quedarse en la página actual')
      return;
    }
    /* Aqui colocar para que se salga */
  console.log('volver hacia atrás')
  //window.history.back();
  if(this._form.getformUrl()){
    await this._router.navigateByUrl( this._form.getformUrl())
  }
    
  }

  

}
